import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import ProjectPostCard from "./project-post-card"

const ProjectGallerySection = ({ data, title, desc }) => (
  <section className="project-gallery-section">
    <div className="gallery-header">
      <div className="title">{title}</div>
      <div className="desc">{desc}</div>
    </div>
    <article className="project-gallery">{data}</article>
  </section>
)

const ProjectGallery = ({ id, title, desc }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { template: { eq: "project-post" } } }
            limit: 6
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  slug
                  title
                  description
                  types
                  company
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 150, maxHeight: 150, quality: 80) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                  gallery
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allMarkdownRemark.edges
          .filter(
            edge =>
              !!edge.node.frontmatter.date &&
              edge.node.frontmatter.gallery === id
          )
          .map(edge => <ProjectPostCard key={edge.node.id} data={edge.node} />)
        return <ProjectGallerySection data={posts} title={title} desc={desc} />
      }}
    />
  )
}
ProjectGallery.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  id: PropTypes.string,
}

export default ProjectGallery
