import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

const ProjectPostCard = ({ data }) => {
  return (
    <Link to={data.frontmatter.slug}>
      <article className="project-post-card">
        {data.frontmatter.featuredImage && (
          <Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            objectFit="cover"
            objectPosition="50% 50%"
            alt={data.frontmatter.title + " - Featured image"}
            imgStyle={{}}
            className="featured-image"
          />
        )}
        <div className="post-content">
          <h2 className="title">{data.frontmatter.title}</h2>
          <p className="company">{data.frontmatter.company}</p>
          {data.frontmatter.types && (
            <div className="types">
              {data.frontmatter.types.map(type => (
                <span className="type" key={"type_+ " + type}>
                  {type}
                </span>
              ))}
            </div>
          )}
          <p className="desc">{data.frontmatter.description}</p>
        </div>
      </article>
    </Link>
  )
}

export default ProjectPostCard
