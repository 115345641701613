import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import GameGallery from "../components/game-gallery-section"
import SEO from "../components/seo"
import ProjectGallery from "../components/project-gallery-section"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        desc
        galleries {
          id
          title
          desc
        }
        projects {
          id
          title
          desc
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter } = markdownRemark

  return (
    <Layout>
      <SEO />
      <div className="home-container">
        <h1 className="title">{frontmatter.title}</h1>
        <p className="desc">{frontmatter.desc}</p>
        <hr />
        <br />
        {frontmatter.galleries.map(gallery => (
          <GameGallery key={gallery.id} {...gallery} />
        ))}
        {frontmatter.projects.map(gallery => (
          <ProjectGallery key={gallery.id} {...gallery} />
        ))}
      </div>
    </Layout>
  )
}

export default HomePage
