import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const GamePostCard = ({ data }) => {
  const imgPos = data.frontmatter.featuredImagePosition
    ? data.frontmatter.featuredImagePosition
    : "50% 50%"
  return (
    <article className="game-post-card">
      <div className="title-bar">{data.frontmatter.title}</div>
      {data.frontmatter.featuredImage && (
        <Link to={data.frontmatter.slug}>
          <Img
            fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
            alt={data.frontmatter.title + " - Featured image"}
            style={{ maxHeight: "100px" }}
            imgStyle={{
              objectPosition: imgPos,
            }}
          />
        </Link>
      )}
      <div className="detail-bar">
        <span className="blurb">{data.frontmatter.blurb}</span>
        {data.frontmatter.gamejam && (
          <span className="game-jam">
            <b>Game jam</b>: {data.frontmatter.gamejam}
          </span>
        )}
        {data.frontmatter.itchio && (
          <a className="itchio-link" href={data.frontmatter.itchio}>
            Play on itch.io
          </a>
        )}
      </div>
      {/* <div className="details">
      <p className="title">{data.frontmatter.title}</p>
      <p className="blurb">{data.frontmatter.blurb}</p>
    </div> */}
    </article>
  )
}

export default GamePostCard
